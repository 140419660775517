<template>
  <div class="month">
    <div>ciao demis</div>
    <div>{{ monthname }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Month",
  setup() {
    const monthname = ref("gennaio");
    return { monthname };
  },
});
</script>
