
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Month",
  setup() {
    const monthname = ref("gennaio");
    return { monthname };
  },
});
